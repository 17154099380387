
import { Component, Vue } from "vue-property-decorator";
import ListaEncuestas from "@/components/Encuesta/ListaEncuestas.vue";

@Component({
  components: {
    ListaEncuestas
  }
})
export default class ListaEncuestasView extends Vue {}
