
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
@Component
export default class ListaEncuestas extends Mixins(FormValidator) {
  private encuestas: any = null;

  private mounted() {
    this.getEncuesta();
  }

  private getEncuesta() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/lista/encuestas")
      .then((res: any) => {
        this.encuestas = res.data;
        for (let i = 0; i < this.encuestas.length; i++) {
          if (this.encuestas[i].foto) {
            this.encuestas[i].foto =
              process.env.VUE_APP_MEDIA_URL + "/" + this.encuestas[i].foto;
          }
          if (this.encuestas[i].foto_mobile) {
            this.encuestas[i].foto_mobile =
              process.env.VUE_APP_MEDIA_URL + "/" +  this.encuestas[i].foto_mobile;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.encuestas = [];
      });
  }

  private goEncuesta(encuesta: any) {
    if (encuesta && encuesta.id && encuesta.id != "") {
      if (
        this.$router.currentRoute.name == "Encuesta" &&
        this.$router.currentRoute.params.id &&
        this.$router.currentRoute.params.id == encuesta.id
      ) {
        this.$router.go(0);
      } else {
        if (this.$router.currentRoute.name == "Encuesta") {
          if (
            this.$router.currentRoute.params.id &&
            this.$router.currentRoute.params.id != encuesta.id
          ) {
            this.$router
              .push({
                name: "Encuesta",
                params: { id: encuesta.id, nombre: encuesta.nombre_web }
              })
              .catch(err => {
                console.log(err);
              });
            this.$router.go(0);
          } else {
            this.$router
              .push({
                name: "Encuesta",
                params: { id: encuesta.id, nombre: encuesta.nombre_web }
              })
              .catch(err => {
                console.log(err);
              });
          }
        } else {
          this.$router
            .push({
              name: "Encuesta",
              params: { id: encuesta.id, nombre: encuesta.nombre_web }
            })
            .catch(err => {
              console.log(err);
            });
        }
      }
    }
  }
}
